/**
 * Accepts two hex values representing the start and end, and the proportion of the way through you are.
 *
 * Returns the hex value of the intermediate colour.
 *
 * @param {String} hexStart
 * @param {String} hexEnd
 * @param {Number} proportion
 */
export const interpolateColors = (hexStart, hexEnd, proportion) => {
  if (proportion === 0.0) {
    return hexStart
  } else if (proportion === 1.0) {
    return hexEnd
  }
  const startRgb = hexToRgb(hexStart)
  const endRgb = hexToRgb(hexEnd)

  const intermediateRgb = {
    r: Math.round(startRgb.r * proportion + endRgb.r * (1 - proportion)),
    g: Math.round(startRgb.g * proportion + endRgb.g * (1 - proportion)),
    b: Math.round(startRgb.b * proportion + endRgb.b * (1 - proportion)),
  }
  return rgbToHex(intermediateRgb)
}

/**
 * Convert hex string to RGB.
 *
 * @param h A hex string like #0905FF or #FAA
 * @returns Object with r, g, and b values in range of 0-255
 */
const hexToRgb = h => {
  let r = undefined, g = undefined, b = undefined

  // 3 digits
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`
    g = `0x${h[2]}${h[2]}`
    b = `0x${h[3]}${h[3]}`

  // 6 digits
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`
    g = `0x${h[3]}${h[4]}`
    b = `0x${h[5]}${h[6]}`
  }

  return { r: Number(r), g: Number(g), b: Number(b) }
}

/**
 * Convert an RGB color to hex
 *
 * @param rgb RGBColor object
 * @returns The hex representation of the color
 */
const rgbToHex = rgb => {
  let r = rgb.r.toString(16)
  let g = rgb.g.toString(16)
  let b = rgb.b.toString(16)

  if (r.length === 1)
    r = `0${r}`
  if (g.length === 1)
    g = `0${g}`
  if (b.length === 1)
    b = `0${b}`

  return `#${r}${g}${b}`
}
