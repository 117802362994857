<template>
  <el-dialog :visible="visible"
    :before-close="handleCancel">
    <template #title>
      <slot name="title">
        <h1 v-if="title">
          {{ $t(title) }}
        </h1>
      </slot>
    </template>
    <template #default>
      <slot>
        <span>{{ $t(body) }}</span>
      </slot>
    </template>
    <template #footer>
      <slot name="footer">
        <el-button data-testid="cancel-button"
          @click="handleCancel">
          {{ $t(leftFooterButtonString) }}
        </el-button>
        <el-button type="primary"
          data-testid="confirm-button"
          @click="handleConfirm">
          {{ $t(rightFooterButtonString) }}
        </el-button>
      </slot>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    body: {
      type: String,
      required: false,
      default: '',
    },
    leftFooterButtonString: {
      type: String,
      required: false,
      default: 'general.cancel',
    },
    rightFooterButtonString: {
      type: String,
      required: false,
      default: 'general.confirm',
    },
  },

  emits: ['open', 'cancel', 'confirm'],

  mounted() {
    this.handleOpen()
  },

  updated() {
    this.handleOpen()
  },

  methods: {
    handleOpen() {
      if (this.visible) this.$emit('open')
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 2.25rem;
  margin: 0;
}
</style>
