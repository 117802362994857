import Vue from 'vue'
import captureSentryError from '@/utils/CaptureSentryError.js'

// This was retrieved by looking up all the ixtypes for all locations and taking unique responses, then adding the
// geo location of the places to the data
const knownIXData = [
  { group_metro: 'Amsterdam', center: [4.9, 52.378] },
  { group_metro: 'Ashburn', center: [-77.48749, 39.043719] },
  { group_metro: 'Atlanta', center: [-84.390264, 33.748992] },
  { group_metro: 'Auckland', center: [174.76318, -36.852095] },
  { group_metro: 'Berlin', center: [13.3888599, 52.5170365] },
  { group_metro: 'Brisbane', center: [153.023499, -27.468968] },
  { group_metro: 'Charlotte', center: [-80.843083, 35.227209] },
  { group_metro: 'Chicago', center: [-87.624421, 41.875562] },
  { group_metro: 'Dallas', center: [-96.796856, 32.776272] },
  { group_metro: 'Dublin', center: [-6.2602964, 53.3498006] },
  { group_metro: 'Dusseldorf', center: [6.776314, 51.225402] },
  { group_metro: 'Frankfurt', center: [8.682092, 50.110644] },
  { group_metro: 'Hamburg', center: [10.000654, 53.550341] },
  { group_metro: 'Hong Kong', center: [114.16255, 22.279356] },
  { group_metro: 'Lab', center: [153.03775, -27.45606] },
  { group_metro: 'Las Vegas', center: [-115.148516, 36.167256] },
  { group_metro: 'London', center: [-0.12764739999999997, 51.507321899999994] },
  { group_metro: 'Los Angeles', center: [-118.242766, 34.053691] },
  { group_metro: 'Melbourne', center: [144.963161, -37.814218] },
  { group_metro: 'Munich', center: [11.5772753, 48.1365601] },
  { group_metro: 'New York', center: [-73.9866, 40.7306] },
  { group_metro: 'Perth', center: [115.86048, -31.952712] },
  { group_metro: 'Seattle', center: [-122.330062, 47.603832] },
  { group_metro: 'Singapore', center: [103.808052586332, 1.3516161224392] },
  { group_metro: 'Sofia', center: [23.322179, 42.697863] },
  { group_metro: 'Stockholm', center: [18.071093, 59.325117] },
  { group_metro: 'Sydney', center: [151.216454, -33.854816] },
  { group_metro: 'Toronto', center: [-79.383935, 43.653482] },
]

export const getIXLocationData = async name => {
  const group_metro = name.endsWith(' IX') ? name.substring(0, name.length - 3) : name
  const centerData = knownIXData.find(data => data.group_metro === group_metro)
  if (centerData) {
    return centerData.center
  }
  const url = new URL(`https://api.mapbox.com/geocoding/v5/mapbox.places/${group_metro}.json`)
  url.search = new URLSearchParams({ access_token: Vue.prototype.$appConfiguration.mapbox.MAPBOX_TOKEN }).toString()
  const result = await window.fetch(url)
  const json = await result.json()
  const bCoordinates = json.features[0].geometry.coordinates
  captureSentryError(new Error(`Please add an entry for group_metro "${group_metro}". The center coordinates should probably be [${bCoordinates[0]}, ${bCoordinates[1]}]`))
  return bCoordinates
}
