<template>
  <section class="map-popup">
    <div class="d-flex flex-align-center mb-1">
      <div class="datacenter-logo-holder">
        <img :src="`https://media.megaport.com/datacentre/${location.dc.id}/62x30.png`"
          :alt="$t('images.datacentre-logo')"
          class="datacentre-logo"
          @error="setFallbackImage($event, 'https://media.megaport.com/datacentre/fallback/MissingDC.svg')">
      </div>
      <div>
        <h2>{{ location.dc.name }}</h2>
        <p class="m-0">
          {{ location.formatted.long }}
        </p>
      </div>
    </div>


    <!-- Display a message if the site has not gone live yet -->
    <el-alert v-if="location.status === 'Deployment'"
      type="warning"
      :closable="false">
      {{ $t('map.location-deployment') }}
    </el-alert>

    <p>{{ $t('map.supported-products') }}</p>

    <p v-for="(line, index) in supportedProducts"
      :key="index"
      class="product">
      {{ line }}
    </p>

    <div class="d-flex flex-justify-center mt-2">
      <el-button v-if="supportsPorts"
        type="primary"
        @click="addPort">
        <div class="flex-row-centered">
          <mu-mega-icon icon="PORT"
            class="icon" />
          <div class="ml-1">
            {{ $t('general.create-thing', { thing: $t('productNames.port') }) }}
          </div>
        </div>
      </el-button>
      <el-button v-if="supportsMCRs"
        type="primary"
        @click="addMCR">
        <div class="flex-row-centered">
          <mu-mega-icon icon="MCR"
            class="icon" />
          <div class="ml-1">
            {{ $t('general.create-thing', { thing: $t('productNames.mcr') }) }}
          </div>
        </div>
      </el-button>
      <el-button v-if="supportsMVEs"
        type="primary"
        @click="addMVE">
        <div class="flex-row-centered">
          <mu-mega-icon icon="MVE"
            class="icon" />
          <div class="ml-1">
            {{ $t('general.create-thing', { thing: $t('productNames.mve') }) }}
          </div>
        </div>
      </el-button>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { setFallbackImage } from '@/utils/fallbackImage'
import { convertSpeed } from '@/helpers'
import { captureEvent } from '@/utils/analyticUtils'

// This needs to be done with the class based interface like this so that we can instantiate it properly without having to wrap it externally.
export default Vue.extend({
  props: {
    location: {
      type: Object,
      required: true,
    },
    disabledFeatures: {
      type: Object,
      required: true,
    },
  },
  computed: {
    supportsPorts() {
      return this.location.products.megaport?.length > 0 && !this.disabledFeatures.addPort
    },
    supportsMCRs() {
      return !!this.location.products.mcr2
    },
    supportsMVEs() {
      return this.location.products.mve?.some(mve => mve.sizes.length > 0)
    },
    supportedProducts() {
      const supportedProducts = []
      if (this.location.products.megaport?.length && !this.disabledFeatures.addPort) {
        supportedProducts.push(`${this.$t('productNames.ports')}: ${this.location.products.megaport.map(speed => convertSpeed(speed * 1000)).join(', ')}`)
      }
      if (this.location.products.mcr2) {
        supportedProducts.push(this.$t('productNames.mcrs'))
      }
      if (this.location.products.mve?.some(mve => mve.sizes.length > 0)) {
        const vendors = new Set()
        for (const mve of this.location.products.mve) {
          if (mve.sizes.length) {
            vendors.add(mve.vendor)
          }
        }
        supportedProducts.push(`${this.$t('productNames.mves')}: ${[...vendors].join(', ')}`)
      }
      return supportedProducts
    },
  },
  methods: {
    setFallbackImage,
    addPort() {
      captureEvent('dashboard.map.location.create-port.click', { locationId: this.location.id })
      this.$emit('addPort', this.location.id)
    },
    addMCR() {
      captureEvent('dashboard.map.location.create-mcr.click', { locationId: this.location.id })
      this.$emit('addMCR', this.location.id)
    },
    addMVE() {
      captureEvent('dashboard.map.location.create-mve.click', { locationId: this.location.id })
      this.$emit('addMVE', this.location.id)
    },
  },
})

</script>

<style lang="scss" scoped>
.map-popup {
  font-size: 1.6rem;
  h2 {
    font-size: 1.6rem;
  }
  p {
    font-size: 1.4rem;
    &.product {
      font-weight: 500;
      margin: 0;
    }
  }
}

svg.icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  fill: currentColor;
  line-height: 0;
}

::v-deep .el-alert__description {
  margin: 0;
  font-weight: 500;
}

.datacenter-logo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 78px;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  margin-right: 1.5rem;
}
</style>
