<template>
  <section id="popupElement"
    class="scrollable-popup"
    :style="{ 'max-height': `${popupHeight}px`}">
    <div class="mr-1">
      <connection-info v-for="connection in connections"
        :key="connection.productUid"
        :connection="connection"
        :highlighted="connection.productUid === highlightedUuid"
        :start="start"
        :end="end"
        @gotoPortLike="gotoPortLike"
        @cancelService="cancelService"
        @gotoIx="gotoIx" />
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import ConnectionInfo from '@/components/map-support/ConnectionInfo.vue'

export default Vue.extend({
  components: {
    ConnectionInfo,
  },
  props: {
    connections: {
      type: Array,
      required: true,
    },
    start: {
      type: Object,
      required: true,
    },
    end: {
      type: Object,
      required: true,
    },
    companyUid: {
      type: String,
      required: true,
    },
    highlightedUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      popupHeight: 0,
    }
  },
  mounted() {
    const mapElement = document.getElementById('mapbox')
    this.popupHeight = mapElement?.offsetHeight * 0.4 || 150

    this.$nextTick(() => {
      const popupElement = document.getElementById('popupElement')
      if (popupElement) popupElement.scrollTop = 0

      if (this.highlightedUuid) {
        const collection = document.getElementsByClassName('highlighted')
        if (collection.length) {
          collection.item(0).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
      }
    })
  },
  methods: {
    // Note that since we are programmatically instantiating this object, the $listeners do not know about the
    // listeners added later with $on, and can therefore not pass them back up to the parent. We therefore need to pass
    // through each event to the parent.
    gotoPortLike(productUid) {
      this.$emit('gotoPortLike', productUid)
    },
    cancelService(productUid) {
      this.$emit('cancelService', productUid)
    },
    gotoIx(ixName) {
      this.$emit('gotoIx', ixName)
    },
  },
})
</script>

<style lang="scss" scoped>
.scrollable-popup {
  font-size: 16px;
  margin: 0;
  line-height: 24px;
  overflow-y: auto;
}
</style>
