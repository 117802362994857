<template>
  <section id="popupElement"
    class="scrollable-popup"
    :style="{ 'max-height': `${popupHeight}px`}">
    <div class="mr-1">
      <div class="ix-item">
        {{ networkServiceType }}
      </div>
      <div v-if="connections.length"
        class="connections">
        <canvas id="connectionsCanvas"
          width="40"
          height="10" />

        <div id="connectionStack"
          class="stack">
          <connection-on-port v-for="connection in connections"
            :key="connection.productUid"
            class="stack-item"
            :connection="connection"
            :a-end-is-i-x="true"
            @navigateToUid="navigateToUid"
            @navigateToIx="navigateToIx" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ConnectionOnPort from './ConnectionOnPort.vue'

export default Vue.extend({
  components: {
    ConnectionOnPort,
  },

  props: {
    networkServiceType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      popupHeight: 0,
    }
  },

  computed: {
    ...mapGetters({
      myConnections: 'Services/myConnections',
    }),

    connections() {
      return this.myConnections.filter(connection => {
        if (connection.productType !== this.G_PRODUCT_TYPE_IX) {
          return false
        }
        if (connection.networkServiceType === this.networkServiceType) {
          return true
        }
        return false
      })
    },
  },

  mounted() {
    const mapElement = document.getElementById('mapbox')
    this.popupHeight = mapElement?.offsetHeight * 0.4 || 150

    this.$nextTick(() => {
      const popupElement = document.getElementById('popupElement')
      if (popupElement) popupElement.scrollTop = 0
      this.drawCanvas()
    })
  },

  methods: {
    navigateToUid(productUid) {
      this.$emit('navigateToUid', productUid)
    },
    navigateToIx(networkServiceType) {
      this.$emit('navigateToIx', networkServiceType)
    },
    drawCanvas() {
      const canvas = document.getElementById('connectionsCanvas')
      if (!canvas) {
        return
      }
      const connectionStack = document.getElementById('connectionStack')
      canvas.height = connectionStack.clientHeight

      const ctx = canvas.getContext('2d')
      ctx.lineWidth = 2
      ctx.strokeStyle = '#cccfd6'

      const parentPos = canvas.getBoundingClientRect()

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      const curveRadius = 10

      for (const element of document.getElementsByClassName('stack-item')) {
        ctx.beginPath()
        ctx.moveTo(20, 0)
        const childPos = element.getBoundingClientRect()

        const top = childPos.top - parentPos.top
        const height = childPos.bottom - childPos.top

        const midPoint = top + height / 2

        ctx.arcTo(20, midPoint, canvas.width, midPoint, curveRadius)
        ctx.lineTo(canvas.width, midPoint)
        ctx.stroke()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.scrollable-popup {
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  overflow-y: auto;
}

.ix-item {
  border: 1px solid #dcdfe6;
  border-radius: var(--border-radius-base);
  padding: 1rem;
  margin: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.connections {
  display: flex;
  flex-direction: row;
}

.stack-item {
  border: 1px solid #dcdfe6;
  border-radius: var(--border-radius-base);
  padding: 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: white;
}

.stack {
  width: 100%;
}
.stack .stack-item {
  margin: 0.5rem 0;
}
</style>
