import {
  GRADIENT_ANGLE,
  UP_COLOR_START,
  UP_COLOR_END,
  DOWN_COLOR_START,
  DOWN_COLOR_END,
  UNKNOWN_COLOR_START,
  UNKNOWN_COLOR_END,
  OCCUPIED_GRADIENT_START,
  OCCUPIED_GRADIENT_END,
} from '@/Globals'

const getTextSize = (text, fontFamily, fontSize, fontWeight) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  ctx['font-family'] = fontFamily
  ctx['font-size'] = fontSize
  ctx['font-weight'] = fontWeight
  const metrics = ctx.measureText(text)
  return {
    width: metrics.width,
    height: metrics.actualBoundingBoxAscent - metrics.actualBoundingBoxDescent,
  }
}

const polarToCartesian = (cx, cy, radius, degrees) => {
  const rad = (degrees - 90) * Math.PI / 180.0
  return {
    x: cx + radius * Math.cos(rad),
    y: cy + radius * Math.sin(rad),
  }
}

const createArc = (cx, cy, radius, startAngle, endAngle) => {
  if (endAngle - startAngle >= 360) {
    return [
      'M',
      cx - radius,
      cy,
      'a',
      radius,
      radius,
      0,
      1,
      0,
      radius * 2,
      0,
      'a',
      radius,
      radius,
      0,
      1,
      0,
      -radius * 2,
      0,
    ].join(' ')
  }

  const start = polarToCartesian(cx, cy, radius, endAngle)
  const end = polarToCartesian(cx, cy, radius, startAngle)

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  return [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ')
}

const createLine = (cx, cy, lineWidth, angle) => {
  const start = polarToCartesian(cx, cy, cx - lineWidth, angle)
  const end = polarToCartesian(cx, cy, cx, angle)
  return [
    'M',
    start.x,
    start.y,
    'L',
    end.x,
    end.y,
  ].join(' ')
}

/**
 * Settings object has the following optional keys:
 * - circleSize: Sets the circle to a fixed size
 * - circleStrokeWidth: sets the width of the circle around the indicator circle
 * - upIndicatorWidth: the width of the graph circle with the coloured lines on it
 * - forceLine: (boolean) force to allow room for a line even if we are not going to draw one (constant size image)
 * - circleFillColor: the fill color to use for the centre circle
 * - fontSize: the size of the number in the middle
 * - textColor: the color for the number in the middle
 * - sectorSplitWidth: the width of the line splitting segments
 *
 * @param {Number} counter The count to put in the center - note that this may be different to the sum of the other items
 * @param {Number} upCount
 * @param {Number} downCount
 * @param {Number} unknownCount
 * @param {Object} settings Overrides for each of the overridable settings
 * @returns
 */
export const createClusterMarkerSvg = (counter, upCount, downCount, unknownCount, settings = {}) => {
  const CIRCLE_MARGIN = 11 // Size to add to text size for size of the red circle in the middle
  const FONT_FAMILY = `'Muli', 'Helvetica Neue', 'Arial', 'sans-serif'`
  const FONT_SIZE = settings.fontSize ?? 20
  const FONT_WEIGHT = settings.fontWeight ?? 500
  const CIRCLE_STROKE_WIDTH = settings.circleStrokeWidth ?? 2 // The white line around the circle
  const UP_INDICATOR_WIDTH = settings.upIndicatorWidth ?? 5
  const SECTOR_SPLIT_WIDTH = settings.sectorSplitWidth ?? 1.5
  const gradientOffset = Math.tan(GRADIENT_ANGLE) / 2 * 100

  const total = upCount + downCount + unknownCount
  const textSize = getTextSize(counter, FONT_FAMILY, FONT_SIZE, FONT_WEIGHT)

  const circleRadius = settings.circleSize ?? Math.max(textSize.width, textSize.height) + CIRCLE_MARGIN
  const iconRadius = circleRadius + CIRCLE_STROKE_WIDTH / 2 + (upCount || downCount || unknownCount || settings.forceLine ? UP_INDICATOR_WIDTH : 0)

  const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svgElement.setAttribute('viewBox', `0 0 ${iconRadius * 2} ${iconRadius * 2}`)
  svgElement.setAttribute('fill', 'none')
  svgElement.setAttribute('width', `${iconRadius * 2}px`)
  svgElement.setAttribute('height', `${iconRadius * 2}px`)

  const defsElement = document.createElementNS('http://www.w3.org/2000/svg', 'defs')
  svgElement.appendChild(defsElement)

  const upLinearGradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient')
  upLinearGradient.setAttribute('id', 'upGradient')
  upLinearGradient.setAttribute('x1', `${50 + gradientOffset}%`)
  upLinearGradient.setAttribute('y1', '0%')
  upLinearGradient.setAttribute('x2', `${50 - gradientOffset}%`)
  upLinearGradient.setAttribute('y2', '100%')
  defsElement.appendChild(upLinearGradient)
  const upStop1 = document.createElementNS('http://www.w3.org/2000/svg', 'stop')
  upStop1.setAttribute('offset', '0%')
  upStop1.setAttribute('stop-color', UP_COLOR_END)
  upLinearGradient.appendChild(upStop1)
  const upStop2 = document.createElementNS('http://www.w3.org/2000/svg', 'stop')
  upStop2.setAttribute('offset', '100%')
  upStop2.setAttribute('stop-color', UP_COLOR_START)
  upLinearGradient.appendChild(upStop2)

  const downLinearGradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient')
  downLinearGradient.setAttribute('id', 'downGradient')
  downLinearGradient.setAttribute('x1', `${50 + gradientOffset}%`)
  downLinearGradient.setAttribute('y1', '0%')
  downLinearGradient.setAttribute('x2', `${50 - gradientOffset}%`)
  downLinearGradient.setAttribute('y2', '100%')
  defsElement.appendChild(downLinearGradient)
  const downStop1 = document.createElementNS('http://www.w3.org/2000/svg', 'stop')
  downStop1.setAttribute('offset', '0%')
  downStop1.setAttribute('stop-color', DOWN_COLOR_END)
  downLinearGradient.appendChild(downStop1)
  const downStop2 = document.createElementNS('http://www.w3.org/2000/svg', 'stop')
  downStop2.setAttribute('offset', '100%')
  downStop2.setAttribute('stop-color', DOWN_COLOR_START)
  downLinearGradient.appendChild(downStop2)

  const unknownLinearGradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient')
  unknownLinearGradient.setAttribute('id', 'unknownGradient')
  unknownLinearGradient.setAttribute('x1', `${50 + gradientOffset}%`)
  unknownLinearGradient.setAttribute('y1', '0%')
  unknownLinearGradient.setAttribute('x2', `${50 - gradientOffset}%`)
  unknownLinearGradient.setAttribute('y2', '100%')
  defsElement.appendChild(unknownLinearGradient)
  const unknownStop1 = document.createElementNS('http://www.w3.org/2000/svg', 'stop')
  unknownStop1.setAttribute('offset', '0%')
  unknownStop1.setAttribute('stop-color', UNKNOWN_COLOR_END)
  unknownLinearGradient.appendChild(unknownStop1)
  const unknownStop2 = document.createElementNS('http://www.w3.org/2000/svg', 'stop')
  unknownStop2.setAttribute('offset', '100%')
  unknownStop2.setAttribute('stop-color', UNKNOWN_COLOR_START)
  unknownLinearGradient.appendChild(unknownStop2)

  const occupiedLinearGradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient')
  occupiedLinearGradient.setAttribute('id', 'occupiedGradient')
  occupiedLinearGradient.setAttribute('x1', `${50 + gradientOffset}%`)
  occupiedLinearGradient.setAttribute('y1', '0%')
  occupiedLinearGradient.setAttribute('x2', `${50 - gradientOffset}%`)
  occupiedLinearGradient.setAttribute('y2', '100%')
  defsElement.appendChild(occupiedLinearGradient)
  const occupiedStop1 = document.createElementNS('http://www.w3.org/2000/svg', 'stop')
  occupiedStop1.setAttribute('offset', '0%')
  occupiedStop1.setAttribute('stop-color', OCCUPIED_GRADIENT_START)
  occupiedLinearGradient.appendChild(occupiedStop1)
  const occupiedStop2 = document.createElementNS('http://www.w3.org/2000/svg', 'stop')
  occupiedStop2.setAttribute('offset', '100%')
  occupiedStop2.setAttribute('stop-color', OCCUPIED_GRADIENT_END)
  occupiedLinearGradient.appendChild(occupiedStop2)

  const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
  circle.setAttribute('cx', iconRadius)
  circle.setAttribute('cy', iconRadius)
  circle.setAttribute('r', circleRadius)
  circle.setAttribute('fill', settings.circleFillColor ?? 'url(#occupiedGradient)')
  circle.setAttribute('stroke', 'white')
  circle.setAttribute('stroke-width', CIRCLE_STROKE_WIDTH)
  svgElement.appendChild(circle)

  const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')
  text.setAttribute('x', '50%')
  text.setAttribute('y', '50%')
  text.setAttribute('alignment-baseline', 'central')
  text.setAttribute('text-anchor', 'middle')
  text.setAttribute('font-family', FONT_FAMILY)
  text.setAttribute('font-size', FONT_SIZE)
  text.setAttribute('font-weight', FONT_WEIGHT)
  text.setAttribute('stroke', 'none')
  text.setAttribute('fill', settings.textColor ?? 'white')
  svgElement.appendChild(text)

  const textNode = document.createTextNode(counter)
  text.appendChild(textNode)

  let numSections = 0
  if (upCount) {
    numSections++
    const upPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    upPath.setAttribute('d', createArc(iconRadius, iconRadius, circleRadius + CIRCLE_STROKE_WIDTH / 2 + UP_INDICATOR_WIDTH / 2, 0, upCount / total * 360))
    upPath.setAttribute('stroke', 'url(#upGradient)')
    upPath.setAttribute('stroke-width', UP_INDICATOR_WIDTH)
    svgElement.appendChild(upPath)
  }
  if (downCount) {
    numSections++
    const downPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    const startAngle = upCount / total * 360
    const endAngle = startAngle + downCount / total * 360
    downPath.setAttribute('d', createArc(iconRadius, iconRadius, circleRadius + CIRCLE_STROKE_WIDTH / 2 + UP_INDICATOR_WIDTH / 2, startAngle, endAngle))
    downPath.setAttribute('stroke', 'url(#downGradient)')
    downPath.setAttribute('stroke-width', UP_INDICATOR_WIDTH)
    svgElement.appendChild(downPath)
  }
  if (unknownCount) {
    numSections++
    const unknownPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    const startAngle = (upCount + downCount) / total * 360
    const endAngle = startAngle + unknownCount / total * 360
    unknownPath.setAttribute('d', createArc(iconRadius, iconRadius, circleRadius + CIRCLE_STROKE_WIDTH / 2 + UP_INDICATOR_WIDTH / 2, startAngle, endAngle))
    unknownPath.setAttribute('stroke', 'url(#unknownGradient)')
    unknownPath.setAttribute('stroke-width', UP_INDICATOR_WIDTH)
    svgElement.appendChild(unknownPath)
  }
  if (!upCount && !downCount && !unknownCount) {
    numSections++
    const unknownPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    unknownPath.setAttribute('d', createArc(iconRadius, iconRadius, circleRadius + CIRCLE_STROKE_WIDTH / 2 + UP_INDICATOR_WIDTH / 2, 0, 360))
    unknownPath.setAttribute('stroke', '#ECECEC')
    unknownPath.setAttribute('stroke-width', UP_INDICATOR_WIDTH)
    svgElement.appendChild(unknownPath)
  }
  // Draw lines across the intersections to make the graph easier to read
  if (numSections > 1) {
    if (upCount) {
      const upPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
      upPath.setAttribute('d', createLine(iconRadius, iconRadius, UP_INDICATOR_WIDTH, upCount / total * 360))
      upPath.setAttribute('stroke', 'white')
      upPath.setAttribute('stroke-width', SECTOR_SPLIT_WIDTH)
      svgElement.appendChild(upPath)
    }
    if (downCount) {
      const downPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
      downPath.setAttribute('d', createLine(iconRadius, iconRadius, UP_INDICATOR_WIDTH, (upCount + downCount) / total * 360))
      downPath.setAttribute('stroke', 'white')
      downPath.setAttribute('stroke-width', SECTOR_SPLIT_WIDTH)
      svgElement.appendChild(downPath)
    }
    if (unknownCount) {
      const unknownPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
      unknownPath.setAttribute('d', createLine(iconRadius, iconRadius, UP_INDICATOR_WIDTH, (upCount + downCount + unknownCount) / total * 360))
      unknownPath.setAttribute('stroke', 'white')
      unknownPath.setAttribute('stroke-width', SECTOR_SPLIT_WIDTH)
      svgElement.appendChild(unknownPath)
    }
  }
  return svgElement
}
