import * as turf from '@turf/turf'

import { convertSpeed } from '@/helpers'
import store from '@/store/Store.js'
import { getIXLocationData } from './ix-location'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { captureEvent } from '@/utils/analyticUtils'

import {
  G_PRODUCT_TYPE_MEGAPORT,
  G_PRODUCT_TYPE_CXC,
  G_PRODUCT_TYPE_VXC,
  G_PRODUCT_TYPE_IX,
} from '@/Globals'
import { locationsMatchingFilter } from '@/utils/MapDataUtils'

export const findKnownFeatures = async query => {
  // We want to have them appear with your own services first, then occupied locations then empty locations
  captureEvent('dashboard.map.search', { query })
  const lcQuery = query.toLocaleLowerCase()
  const results = []

  const ixTypes = store.state.IXTypes.ixTypes
  const locations = store.state.Services.locations
  const filteredLocations = store.getters['Services/filteredLocations']
  const allMyServices = store.getters['Services/allMyServices']
  const myConnections = store.getters['Services/myConnections']
  const ports = store.getters['Services/myPorts']
  const findPortFunction = store.getters['Services/findPort']
  const companyUid = store.getters['Company/companyUid']

  const usedLocationIds = new Set(ports.map(port => port.locationId))
  for (const connection of myConnections) {
    if ([G_PRODUCT_TYPE_CXC, G_PRODUCT_TYPE_VXC].includes(connection.productType)) {
      usedLocationIds.add(connection.aEnd.locationId)
      usedLocationIds.add(connection.bEnd.locationId)
    }
  }

  try {
    // Ports
    results.push(...findMatchingPorts(lcQuery, ports, locations))

    // VXCs
    results.push(...findMatchingVXCs(lcQuery, allMyServices, companyUid, findPortFunction, locations))

    // IXs
    const matchingIXFeatures = await findMatchingIXs(lcQuery, allMyServices, ixTypes, locations)
    results.push(...matchingIXFeatures)

    // Locations
    results.push(...findMatchingLocations(lcQuery, filteredLocations, usedLocationIds))

    return results
  } catch (error) {
    captureSentryError(error)
  }
}

export const findMatchingPorts = (lcQuery, ports, locations) => {
  const matchingPorts = ports.filter(port => {
    const locationStr = port._location ? [port._location.name, port._location.country, port._location.campus].join(' ').toLocaleLowerCase() : ''
    const portDescription = [port.productName, port.productUid, port.productType].join(' | ').toLocaleLowerCase()

    return locationStr.toLocaleLowerCase().includes(lcQuery) || portDescription.toLocaleLowerCase().includes(lcQuery)
  })
  return matchingPorts.map(port => {
    let productType = port.productType
    // For the purposes of the search result we will search all ports in a lag
    if (productType === G_PRODUCT_TYPE_MEGAPORT && (port.lagId || port.lagPortCount)) {
      productType = 'LAG'
    }

    // Use locations rather than filteredLocations just in case the settings have changed and it's outside our normally available locations
    const location = locations.find(loc => loc.id === port.locationId)

    return {
      type: 'Feature',
      properties: {
        mpType: productType,
        title: port.productName,
        details: `${port._speed}, ${port._location?.formatted.long}`,
        productUid: port.productUid,
      },
      geometry: {
        coordinates: [location.longitude, location.latitude],
        type: 'Point',
      },
      // Must have the center defined so that it can go to that locations
      center: [location.longitude, location.latitude],
    }
  })
}

export const findMatchingVXCs = (lcQuery, services, companyUid, findPort, locations) => {
  const matchingVXCs = services.filter(vxc => {
    if (![G_PRODUCT_TYPE_CXC, G_PRODUCT_TYPE_VXC].includes(vxc.productType)) {
      return false
    }
    const vxcDescription = [vxc.productName, vxc.productUid, vxc.productType, vxc.secondaryName].join(' | ').toLocaleLowerCase()
    return vxcDescription.includes(lcQuery)
  })
  return matchingVXCs.map(vxc => {
    // See if we own only one end of the connection.
    const aEndPort = findPort(vxc.aEnd.productUid)
    // This will search our ports and partner ports, but if it's not listed there, it can be undefined
    const bEndPort = findPort(vxc.bEnd.productUid)
    const aEndOwned = aEndPort?.companyUid === companyUid

    const title = !aEndOwned ? (vxc.secondaryName || vxc.productName) : vxc.productName

    const mpType = aEndPort?.connectType === 'TRANSIT' || bEndPort?.connectType === 'TRANSIT' ? 'TRANSIT' : G_PRODUCT_TYPE_VXC

    const aLocation = locations.find(location => location.id === vxc.aEnd.locationId)
    const bLocation = locations.find(location => location.id === vxc.bEnd.locationId)

    const endPoints = turf.points([[aLocation.longitude, aLocation.latitude], [bLocation.longitude, bLocation.latitude]])
    const midPoint = turf.center(endPoints)
    const bbox = turf.bbox(endPoints)
    return {
      type: 'Feature',
      properties: {
        mpType,
        title,
        details: `${convertSpeed(vxc.rateLimit)} ${vxc.provisioningStatus}`,
        productUid: vxc.productUid,
        aLocation,
        bLocation,
      },
      geometry: {
        type: 'LineString',
        coordinates: endPoints,
      },
      center: midPoint.geometry.coordinates,
      bbox,
    }
  })
}

export const findMatchingIXs = async (lcQuery, services, ixTypes, locations) => {
  const matchingIXs = services.filter(ix => {
    if (ix.productType !== G_PRODUCT_TYPE_IX) {
      return false
    }
    const ixDescription = [ix.productName, ix.productUid, ix.productType, ix.networkServiceType].join(' | ').toLocaleLowerCase()
    return ixDescription.includes(lcQuery)
  })
  return await Promise.all(matchingIXs.map(async ix => {
    let destinationDescription = undefined
    if (!ixTypes[ix.locationId]) {
      await store.dispatch('IXTypes/getIxType', ix.locationId)
    }

    const foundInfo = ixTypes[ix.locationId].find(loc => loc.name === ix.networkServiceType)
    if (foundInfo) {
      destinationDescription = foundInfo.description || foundInfo.name
    } else {
      destinationDescription = ix.networkServiceType
    }

    // For an IX, we know the location of the port we are connecting from based on the locationId of the IX, but the destination exact location is hidden from us,
    // so we will use the ixTypes array to find the name and reverse engineer from there.
    const aLocation = locations.find(location => location.id === ix.locationId)
    let midPoint = undefined
    let endPoints = undefined
    let bbox = undefined
    if (foundInfo) {
      const bCoordinates = await getIXLocationData(foundInfo.name)
      endPoints = turf.points([[aLocation.longitude, aLocation.latitude], bCoordinates])
      midPoint = turf.center(endPoints)
      bbox = turf.bbox(endPoints)
    }

    return {
      type: 'Feature',
      properties: {
        mpType: G_PRODUCT_TYPE_IX,
        title: ix.productName,
        details: `${convertSpeed(ix.rateLimit)} - ${destinationDescription}`,
        productUid: ix.productUid,
      },
      geometry: {
        type: endPoints ? 'LineString' : 'Point',
        coordinates: endPoints ? endPoints : [aLocation.longitude, aLocation.latitude],
      },
      center: midPoint ? midPoint.geometry.coordinates : [aLocation.longitude, aLocation.latitude],
      bbox,
    }
  }))
}

export const findMatchingLocations = (lcQuery, filteredLocations, usedLocationIds) => {
  // TODO: Need to apply filters
  const applicableLocations = locationsMatchingFilter(filteredLocations)
  const matchingLocations = applicableLocations.filter(location => {
    if (!['Deployment', 'Active'].includes(location.status)) {
      return false
    }
    const locationDescription = [location.name, ...Object.values(location.address)].join(' | ').toLocaleLowerCase()
    return locationDescription.includes(lcQuery) && usedLocationIds.has(location.id)
  }).sort((a, b) => {
    const usedA = usedLocationIds.has(a.id)
    const usedB = usedLocationIds.has(b.id)
    if (usedA && !usedB) return -1
    if (!usedA && usedB) return 1
    return 0
  })
  return matchingLocations.map(location => {
    const desiredKeys = ['street', 'suburb', 'city', 'state', 'country', 'postcode']
    const addressValues = []
    for (const key of desiredKeys) {
      if (location.address[key]) {
        addressValues.push(location.address[key])
      }
    }

    return {
      type: 'Feature',
      properties: {
        mpType: usedLocationIds.has(location.id) ? 'OccupiedLocation' : 'EmptyLocation',
        title: location.name,
        details: addressValues.join(', '),
        locationId: location.id,
      },
      geometry: {
        coordinates: [location.longitude, location.latitude],
        type: 'Point',
      },
      // Must have the center defined so that it can go to that locations
      center: [location.longitude, location.latitude],
    }
  })
}
