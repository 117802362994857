import store from '@/store/Store.js'
import {
  G_PRODUCT_TYPE_VXC,
  G_PRODUCT_TYPE_CXC,
} from '@/Globals'

const findPortFunction = store.getters['Services/findPort']

export const findPortOrPartnerInfo = productUid => {
  const port = findPortFunction(productUid)
  if (port) {
    return port
  }
  let knownInfo = undefined
  const myConnections = store.getters['Services/myConnections']
  const associatedVxcs = myConnections.filter(connection => connection.productType === G_PRODUCT_TYPE_VXC && (connection.aEnd.productUid === productUid || connection.bEnd.productUid === productUid))

  for (const connection of myConnections) {
    if (![G_PRODUCT_TYPE_CXC, G_PRODUCT_TYPE_VXC].includes(connection.productType)) {
      continue
    }
    if (connection.aEnd.productUid === productUid) {
      knownInfo = {
        ...connection.aEnd,
        associatedVxcs,
        associatedIxs: [],
        thirdParty: true,
      }
      break
    }
    if (connection.bEnd.productUid === productUid) {
      knownInfo = {
        ...connection.bEnd,
        associatedVxcs,
        associatedIxs: [],
        thirdParty: true,
      }
      break
    }
  }

  return knownInfo
}


/**
 * The expected input for this is a lngLat pair for the start and a lngLat pair for the end.
 *
 * This function will determine whether the start and end longitudes are more than 180 degrees
 * away from each other and if necessary will adjust the coordinates so they are closer together.
 *
 * This adjusts the values in place.
 * @param {Array} coordinates
 */
export const normaliseLineCoordinates = geometry => {
  const startLng = geometry[0][0]
  const endLng = geometry[1][0]

  if (endLng - startLng > 180) {
    geometry[1][0] -= 360
  } else if (startLng - endLng > 180) {
    geometry[1][0] += 360
  }
}

/**
 * Accepts a start and end coordinate pair and returns an array of coordinate pairs with a percentage
 * of the way along the original line.
 *
 * @param {Array} startCoordinates
 * @param {Array} endCoordinates
 */
export const splitCoordinates = (startCoordinates, endCoordinates) => {
  const meridianSplitCoordinates = splitMeridianCoordinates(startCoordinates, endCoordinates)

  const coordinates = []
  const startLng = startCoordinates[0]
  const startLat = startCoordinates[1]
  const endLng = endCoordinates[0]
  const endLat = endCoordinates[1]

  // Split the line into halves so that the centre marker will line up with
  // the centre of the line. We need to handle the meridian case so that the
  // centre point will be on the correct side of the meridian.
  let midSection = undefined
  if (meridianSplitCoordinates.length === 4) {
    const startToMeridian = 180 - startLng
    const endToMeridian = endLng + 180
    if (startToMeridian >= endToMeridian) {
      midSection = [startLng + (startToMeridian + endToMeridian) / 2, (startLat + endLat) / 2, 0.5]
    } else {
      midSection = [endLng - (startToMeridian + endToMeridian) / 2, (startLat + endLat) / 2, 0.5]
    }
  } else {
    midSection = [(startLng + endLng) / 2, (startLat + endLat) / 2, 0.5]
  }

  for (let i = 0; i <= meridianSplitCoordinates.length / 2; i += 2) {
    const startSection = meridianSplitCoordinates[i]
    const endSection = meridianSplitCoordinates[i + 1]
    // Normal case where it goes from start to end
    if ((startSection[2] === 0.0 && endSection[2] === 1.0) || // Normal case where it goes from start to end
      (startSection[2] === 0.0 && endSection[2] >= 0.5) || // It has beem split across the meridian and this portion is all to the left of the meridian
      (startSection[2] < 0.5 && endSection[2] === 1.0) // It has beem split across the meridian and this portion starts at the meridian and finishes at the end
    ) {
      coordinates.push(startSection)
      coordinates.push(midSection)
      coordinates.push(midSection)
      coordinates.push(endSection)
    } else {
      coordinates.push(startSection)
      coordinates.push(endSection)
    }
  }

  return coordinates
}

/**
 * Given start and end points, check if it crosses the meridian and if so, split it into two parts, one each side of the meridian.
 * If not, just return the original coordinates. Add a proportion along the line to each coordinate pair.
 *
 * @param {Array} startCoordinates
 * @param {Array} endCoordinates
 */
const splitMeridianCoordinates = (startCoordinates, endCoordinates) => {
  const startLng = startCoordinates[0]
  const startLat = startCoordinates[1]
  const endLng = endCoordinates[0]
  const endLat = endCoordinates[1]

  // Since we always sort the ends before this, the only ones that go across the meridian would be in this
  // direction
  const startToMeridian = 180 - startLng
  const endToMeridian = endLng + 180
  if (startLng > 0 && endLng < 0 && (startToMeridian + endToMeridian) < 180) {
    const proportion = startToMeridian / (startToMeridian + endToMeridian)
    const deltaY = endLat - startLat
    const intermediatePoint1 = [180, startLat + deltaY * proportion, proportion]
    const intermediatePoint2 = [-180, startLat + deltaY * proportion, proportion]
    return [[...startCoordinates, 0.0], intermediatePoint1, intermediatePoint2, [...endCoordinates, 1.0]]
  } else {
    return [[...startCoordinates, 0.0], [...endCoordinates, 1.0]]
  }
}

export const midpointAcrossMeridian = (startCoordinates, endCoordinates) => {
  const startLng = startCoordinates[0]
  const endLng = endCoordinates[0]
  const startLat = startCoordinates[1]
  const endLat = endCoordinates[1]

  const deltaX = endLng - startLng
  const deltaY = endLat - startLat

  const startToMeridian = 180 - startLng
  const endToMeridian = endLng + 180

  if (startLng > 0 && endLng < 0 && (startToMeridian + endToMeridian) < 180) {
    const proportion = startToMeridian / (startToMeridian + endToMeridian)
    if (proportion < 0.5) {
      return [endLng - (startToMeridian + endToMeridian) / 2, startLat + deltaY / 2]
    }
    return [startLng + (startToMeridian + endToMeridian) / 2, startLat + deltaY / 2]
  } else {
    const result = [startLng + deltaX / 2, startLat + deltaY / 2]
    return result
  }
}

/**
 * Returns minX, minY, maxX, maxY
 *
 * @param {Array} startCoordinates
 * @param {Array} endCoordinates
 */
export const bboxAcrossMeridian = (startCoordinates, endCoordinates) => {
  const minX = Math.min(startCoordinates[0], endCoordinates[0])
  const maxX = Math.max(startCoordinates[0], endCoordinates[0])
  const minY = Math.min(startCoordinates[1], endCoordinates[1])
  const maxY = Math.max(startCoordinates[1], endCoordinates[1])
  const startToMeridian = 180 - maxX
  const endToMeridian = minX + 180

  if (maxX > 0 && minX < 0 && (startToMeridian + endToMeridian) < 180) {
    return [maxX, minY, minX + 360, maxY]
  }
  return [minX, minY, maxX, maxY]
}
