<template>
  <div class="el-card flex-column is-always-shadow justify-content-space-between">
    <div v-if="$slots.header"
      class="el-card__header">
      <slot name="header" />
    </div>
    <div class="el-card__body">
      <slot />
    </div>
    <div v-if="$slots.footer"
      class="el-card__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MPCard',
}
</script>

<style lang="scss" scoped>
.el-card__footer {
  padding: 18px 20px;
  border-top: 1px solid var(--card-border-color);
  box-sizing: border-box;
  text-align: center;
}
</style>
