<template>
  <section id="popupElement"
    class="scrollable-popup"
    :style="{ 'max-height': `${popupHeight}px`}">
    <div class="mr-1">
      <div class="port-item">
        <div class="d-flex flex-align-center">
          <div class="datacenter-logo-holder">
            <img v-if="marketplaceProfile"
              :src="marketplaceProfile.logo"
              :alt="$t('images.profile-logo')"
              class="mxlogo"
              :title="marketplaceProfile.companyName"
              @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
            <mu-mega-icon v-else
              icon="Marketplace"
              class="icon" />
          </div>
          <div>
            <h2>{{ port.companyName || port.productName }}</h2>
            <p class="m-0">
              {{ port.title || port.location }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="connections.length"
        class="connections">
        <canvas id="connectionsCanvas"
          width="40"
          height="10" />

        <div id="connectionStack"
          class="stack">
          <connection-on-port v-for="connection in connections"
            :key="connection.productUid"
            class="stack-item"
            :connection="connection"
            :port="port"
            @navigateToUid="navigateToUid"
            @navigateToIx="navigateToIx" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import ConnectionOnPort from './ConnectionOnPort.vue'
import { setFallbackImage } from '@/utils/fallbackImage'
import {
  findPortOrPartnerInfo,
} from '@/components/map-support/map-utilities'

export default Vue.extend({
  components: {
    ConnectionOnPort,
  },

  props: {
    productUid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      popupHeight: 0,
    }
  },

  computed: {
    ...mapGetters({
      findPort: 'Services/findPort',
      myConnections: 'Services/myConnections',
    }),
    ...mapState('Marketplace', ['marketplaceData']),

    port() {
      return findPortOrPartnerInfo(this.productUid)
    },
    marketplaceProfile() {
      return this.marketplaceData.find(mp => this.port.companyUid === mp.companyUid)
    },
    connections() {
      return this.myConnections.filter(connection => {
        if (![this.G_PRODUCT_TYPE_CXC, this.G_PRODUCT_TYPE_VXC].includes(connection.productType)) {
          return false
        }
        if (connection.aEnd.productUid === this.port.productUid) {
          return true
        }
        if (connection.bEnd.productUid === this.port.productUid) {
          return true
        }
        return false
      })
    },
  },

  watch: {
    port: {
      deep: true,
      handler() {
        this.drawCanvas()
      },
    },

    myConnections(newVal) {
      if (!newVal.find(connection => connection.productUid === this.productUid)) {
        this.$emit('close')
      }
    },
  },

  mounted() {
    const mapElement = document.getElementById('mapbox')
    this.popupHeight = mapElement?.offsetHeight * 0.4 || 150

    this.$nextTick(() => {
      const popupElement = document.getElementById('popupElement')
      if (popupElement) popupElement.scrollTop = 0
      this.drawCanvas()
    })
  },

  methods: {
    setFallbackImage,
    navigateToUid(productUid) {
      this.$emit('navigateToUid', productUid)
    },
    navigateToIx(networkServiceType) {
      this.$emit('navigateToIx', networkServiceType)
    },
    drawCanvas() {
      const canvas = document.getElementById('connectionsCanvas')
      if (!canvas) {
        return
      }
      const connectionStack = document.getElementById('connectionStack')
      canvas.height = connectionStack.clientHeight

      const ctx = canvas.getContext('2d')
      ctx.lineWidth = 2
      ctx.strokeStyle = '#cccfd6'

      const parentPos = canvas.getBoundingClientRect()

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      const curveRadius = 10

      for (const element of document.getElementsByClassName('stack-item')) {
        ctx.beginPath()
        ctx.moveTo(20, 0)
        const childPos = element.getBoundingClientRect()

        const top = childPos.top - parentPos.top
        const height = childPos.bottom - childPos.top

        const midPoint = top + height / 2

        ctx.arcTo(20, midPoint, canvas.width, midPoint, curveRadius)
        ctx.lineTo(canvas.width, midPoint)
        ctx.stroke()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.scrollable-popup {
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  overflow-y: auto;
}

.port-item {
  border: 1px solid #dcdfe6;
  border-radius: var(--border-radius-base);
  padding: 1rem;
  margin: 0;
  background-color: white;
  h2 {
    font-size: 1.8rem;
  }
}

.datacenter-logo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 78px;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  margin-right: 1.5rem;
}

.mxlogo {
  height: 4rem;
}
svg.icon {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  fill: currentColor;
  line-height: 0;
}

.connections {
  display: flex;
  flex-direction: row;
}

.stack-item {
  border: 1px solid #dcdfe6;
  border-radius: var(--border-radius-base);
  padding: 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: white;
}

.stack {
  width: 100%;
}
.stack .stack-item {
  margin: 0.5rem 0;
}
</style>
